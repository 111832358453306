import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { createApi } from 'unsplash-js';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getBaseUrl() {
  const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV;
  switch (vercelEnv) {
    case 'production':
      return 'https://www.railee.com';
    case 'preview':
      return `https://${getPublicEnvVariable(process.env.NEXT_PUBLIC_VERCEL_URL, 'NEXT_PUBLIC_VERCEL_URL')}`;
    default:
      return 'http://localhost:3011';
  }
}

export function getUnsplashApi() {
  return createApi({ apiUrl: getBaseUrl() + '/api/unsplash-proxy' });
}

const PRECISION_CENTS = 2;

export function toStringPrice(price: number): string {
  const withCents = Math.round((price * 100) % 100) !== 0;
  const precision = withCents ? PRECISION_CENTS : 0;
  return price?.toFixed(precision);
}

export function toStringPriceNoCents(price: number): string {
  return price?.toFixed(0);
}

export function toNumberPriceNoCents(price: number): number {
  return parseInt(price.toFixed(0));
}

export function htmlEncode(str: string) {
  const replacements = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&apos;',
  };

  return str.replace(/[&<>"']/g, (match) => replacements[match]);
}

function isFeatureFlag(name: string) {
  return name.includes('FEATURE_FLAG');
}

const alreadyReportedMissingVariable = new Set<string>();

export function getPublicEnvVariable<T>(v: T | undefined, variableName: string): T {
  if (!variableName.startsWith('NEXT_PUBLIC_')) {
    throw new Error(
      `You need to add NEXT_PUBLIC_ to variable so that they are exposed to the front-end: ${variableName}`
    );
  }
  if (v === undefined) {
    if (isFeatureFlag(variableName)) {
      if (
        process.env.NODE_ENV !== 'production' &&
        !alreadyReportedMissingVariable.has(variableName)
      ) {
        console.warn(
          `Missing environment variable: ${variableName}, defaulting to false`
        );
        alreadyReportedMissingVariable.add(variableName);
      }
      return 'false' as unknown as T;
    }
    throw new Error(`Missing environment variable: ${variableName}`);
  }

  return v;
}
