import * as React from 'react';

import { VariantProps, cva } from 'class-variance-authority';

import { cn } from 'lib/utilsClientSide';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background whitespace-nowrap',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        confirmative: 'bg-orange text-primary-foreground hover:bg-orange/80',
        destructive:
          'bg-destructive/90 text-destructive-foreground hover:bg-destructive/80',
        outline: 'border border-input hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'underline-offset-4 hover:underline text-primary',
        notion: 'text-primary underline font-bold hover:bg-secondary translate-y-0.5',
      },
      size: {
        default: 'h-10 py-2 px-4 rounded-full',
        sm: 'h-9 px-3 rounded-full',
        xs: 'h-7 px-2 rounded-full',
        lg: 'h-11 px-8 rounded-full',
        xl: 'h-12 px-10 rounded-2xl',
        selector: 'h-10 px-3 py-2 rounded-full',
        icon: 'h-10 w-10 rounded-full',
        notion: 'h-6 py-1 px-2 rounded-md',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      // eslint-disable-next-line localrules/missing-ids
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
